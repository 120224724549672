import React from "react";
import { Fade } from "react-awesome-reveal";
import { SkillsContainer, SkillImage, Title, SkillsWrapper } from "./SkillsSectionElements";
import reactLogo from "../../images/logos/reactLogo.png";
import jsLogo from "../../images/logos/jsLogo.png";
import figmaLogo from "../../images/logos/figmaLogo.png";
import mysqlLogo from "../../images/logos/mysqlLogo.png";
import nodeLogo from "../../images/logos/nodeLogo.png";
import unityLogo from "../../images/logos/unityLogo.png";
// import {angularLogo, figmaLogo, jsLogo, mysqlLogo, nodeLogo, reactLogo, unityLogo} from '../../images/logos'


const SkillsSection = () => {

    return (
        <>
            <SkillsContainer id = 'skills'>
                <Title>Skills</Title>
                <SkillsWrapper>
                    <Fade direction = "left" duration = {2000}>
                        <SkillImage src = {reactLogo} alt = "skills"/>
                        <SkillImage src = {jsLogo} alt = "skills"/>
                        <SkillImage src = {figmaLogo} alt = "skills"/>
                        <SkillImage src = {mysqlLogo} alt = "skills"/>
                        <SkillImage src = {nodeLogo} alt = "skills"/>
                        <SkillImage src = {unityLogo} alt = "skills"/>
                    </Fade>
                </SkillsWrapper>
            </SkillsContainer>
        </>
    );
}

export default SkillsSection