
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/Home';
import Project from './pages/Project';


function App() {
  return (
    <Router>
      <Routes>
        <Route path = "/" element = {<Home/>}/>
        <Route path = "/projects:id"  element = {<Project/>}/>
      </Routes>
    </Router>
  );
}

export default App;
