import styled from "styled-components"
import {FaTimes} from 'react-icons/fa'
import {Link as LinkS} from 'react-scroll'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #247BA0;
    display: grid;
    align-items: center;
    top:0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity:${({isOpen})=> (isOpen ? '100%' : '0')} ;
    top: ${({isOpen})=> (isOpen ? '0' : '-100%' )};

`
export const SidebarBtn = styled.div`
   position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;


`
export const CloseIcon = styled(FaTimes)`
    color: white;

`
export const SidebarMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
   

    @media screen and (max-width: 480px){
        grid-template-rows: repeat(6, 60px);
    }
`

export const SidebarLink = styled(LinkS)`
    color: white;
    font-size: 1.5rem;

    &:hover{
        color: #70c1b3;
        transition: 0.2s ease-in-out;
    }

`