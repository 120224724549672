import img1 from "../../images/img1.JPG"
import img2 from "../../images/img2.png"

export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Armando Gutiérrez Rojo',
    headLine: 'Software Engineer',
    description: 'Student at Tecnológico de Monterrey Campus Querétaro',
    buttonLabel: 'Portfolio',
    imgStart: false,
    img: img1,
    alt: 'Me',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjTwo = {
    id: 'experience',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Armando Gutiérrez Rojo',
    headLine: 'Ingeniero en Tecnologías computacionales',
    description: 'Estudiante en el Tecnológico de Monterrey Campus Querétaro',
    buttonLabel: 'Mi portafolio ',
    imgStart: true,
    img: img2,
    alt: 'Tec',
    dark: false,
    primary: false,
    darkText: true,
}