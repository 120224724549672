import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background-color: #247BA0;
    height: 80px;
    /* margin-top: -80px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top : 0;
    z-index: 10;
    transition: 0.8 all ease;

`
export const NavbarContainer = styled.div`
    width: 100%;
    
    padding: 0 50px;
    display: flex;
    
`

export const NavbarGlogo = styled.div`
    padding-left: 1.5rem;
    size: 2rem;
    color: white;
    font-size: 2.5rem;
    cursor: pointer;

    &:hover{
        color: #70C1B3;
    }
   
`

export const NavbarLinks = styled(LinkS)`
    color: white;
    cursor: pointer;

    &.active{
        border-bottom: 3px solid #f25f5c;
    }
    
    

`
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px){
        display: none;
    }

`

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top:0;
        right:0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`

export const NavbarItem = styled.li`
    border-radius: 50px;
    padding: 0 50px;
    justify-content: space-between;
`