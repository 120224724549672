import React from 'react'
import {SidebarContainer, SidebarBtn, CloseIcon, SidebarMenu, SidebarLink} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    
    return (
        <>
            <SidebarContainer isOpen = {isOpen} onClick={toggle}>
                <SidebarBtn onClick={toggle}>
                    <CloseIcon />
                </SidebarBtn>
                <SidebarMenu>
                        <SidebarLink to= "about" onClick={toggle}>
                            About me
                        </SidebarLink>
                        <SidebarLink to= "experience" onClick={toggle}>
                            Experience
                        </SidebarLink>
                        <SidebarLink to= "projects" onClick={toggle}>
                            Projects
                        </SidebarLink>
                        <SidebarLink to= "skills" onClick={toggle}>
                            Skills
                        </SidebarLink>
                </SidebarMenu>
            </SidebarContainer>
            
        </>
    )
}

export default Sidebar
