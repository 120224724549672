import img2 from "../../images/img2.png";
import img3 from "../../images/img3.png";
import img4 from "../../images/img4.jpeg";
import img5 from "../../images/img5.png";
import img6 from "../../images/img6.png";
import img7 from "../../images/img7.png";
import img8 from "../../images/img8.png";

export const experienceObjOne = {
    id: 'experience',
    title: 'Experience',
    link1: 'https://tec.mx/es',
    link2: 'https://www.natgas.com.mx/home',
    link3: 'https://startelecom.com.mx',
    link4: 'https://www.hahnautomation.com/en/',
    lightBg: false,
    lessColumns: true,
    cardOneTopLine: 'ITESM',
    cardOneHeadLine: '2020-2024',
    cardOneDescription: 'Software Engineering',
    cardOneIcon: img2,
    cardTwoTopLine: 'Natgas',
    cardTwoHeadLine: 'May 2022 - April 2023',
    cardTwoDescription: 'Software Engineer (Full stack)',
    cardTwoIcon: img3,
    cardThreeTopLine: 'Startelecom',
    cardThreeHeadLine: 'Summer 2019',
    cardThreeDescription: 'Software Engineer (Front end)',
    cardThreeIcon: img4,
    cardFourTopLine: 'Hahn Automation',
    cardFourHeadLine: 'September 2023 - Present',
    cardFourDescription: 'Software Engineer (Database team)',
    cardFourIcon: img7,
}

export const experienceObjTwo = {
    id: 'projects',
    title: 'Projects',
    lightBg: true,
    link1: '/projects',
    link2: '/projects',
    link3: '/projects',
    link4: '/projects',
    lessColumns: true,
    cardOneTopLine: 'Genesis',
    cardOneHeadLine: 'June 2023',
    cardOneDescription: 'A software developmet department with level 2.7 maturity in the CMMI ',
    cardOneIcon: img8,
    cardTwoTopLine: 'Rarr: Mobile app',
    cardTwoHeadLine: 'October 2022',
    cardTwoDescription: 'A mobile event management application that allows users to create, edit, and publish events, as well as facilitate access to these events.',
    cardTwoIcon: img6,
    cardThreeTopLine: 'Human Resources',
    cardThreeHeadLine: 'June 2022',
    cardThreeDescription: 'A React and Node.js project for helping the Human Resources department',
    cardThreeIcon: img3,
    cardFourTopLine: 'Traffic simulation',
    cardFourHeadLine: 'November 2022',
    cardFourDescription: 'A simulation of cars moving in a three lane highway and reacting to a stopped car.',
    cardFourIcon: img5,
}

