import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import React, {useState} from 'react'
import HeroSection from '../../components/HeroSection';
import InfoSection from '../../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../../components/InfoSection/Data';
import { ExperienceSection } from '../../components/ExperienceSection';
import { experienceObjOne, experienceObjTwo } from '../../components/ExperienceSection/Data';
import { mainObj } from '../Project/Data';
import SkillsSection from '../../components/SkillsSection';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () =>{
        setIsOpen(!isOpen);
        console.log(isOpen);
        
    }
    return (
        <>
            <Sidebar 
                isOpen = {isOpen}
                toggle = {toggle}
            />
            <Navbar
                toggle = {toggle}
                home = {true}
            />
            <HeroSection {...mainObj}/>
            <InfoSection {...homeObjOne}/>
            <ExperienceSection {...experienceObjOne}/>
            <ExperienceSection {...experienceObjTwo}/>
            <SkillsSection />

        </>
    )
}

export default Home
