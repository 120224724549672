import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import React, { useState } from "react";
import HeroSection from "../../components/HeroSection";
import {
  itemsListOne,
  projectObjOne,
  projectObjTwo,
  itemsListTwo,
  projectObjThree,
  itemsListThree,
  projectObjFour,
  itemsListFour,
} from "./Data";
import { useParams } from "react-router";
import DetailsSection from "../../components/DetailsSection";
const Project = () => {
  const { id } = useParams();
  if (id === "1") {
    var heroObj = projectObjOne;
    var itemsList = itemsListOne;
    var genesis = true;
    var downloadButton = false;
    var android = false;
    var link =
      "https://youtu.be/IBNs3fhhiDM";
  } else if (id === "2") {
    var heroObj = projectObjTwo;
    var itemsList = itemsListTwo;
    var downloadButton = true;
    var android = false;
    var genesis = false;
    var link = "https://drive.google.com/file/d/1wXb6VYL_5RAo5cI667-KPIjqZWWS0vxU/view?usp=sharing";
  } else if (id === "3") {
    var heroObj = projectObjThree;
    var itemsList = itemsListThree;
    var downloadButton = true;
    var android = false;
    var genesis = false;
    var link = "https://youtu.be/ZL3HY-n6jsw";
  } else if (id === "4") {
    var heroObj = projectObjFour;
    var itemsList = itemsListFour;
    var downloadButton = true;
    var android = false;
    var genesis = false;
    var link = "https://youtu.be/qFl5k6BGr5o";
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} home={false} />
      <HeroSection {...heroObj} />
      <DetailsSection
        itemsList={itemsList}
        downloadButton={downloadButton}
        link={link}
        android={android}
        genesis={genesis}
      />
    </>
  );
};

export default Project;
