import styled from "styled-components";


export const HeroContainer = styled.div`
    display:flex;
    background-color: #0c0c0c;
    justify-content: right;
    align-items: center;
    height: 1000px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding-right: 24px;
    
    @media screen and (max-width:425px ){
        height: 500px;
    }

    @media screen and (max-width:768px ){
        height: 700px;
    }
   
    
    
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit:cover;
    object-fit: cover;
    background: #232a34;
    opacity: 60%;

`

export const VideoBg2 = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit:cover;
    object-fit: cover;
    background: #232a34;
    opacity: 10%;

`
export const TextSection = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 64px;

`
export const Name = styled.h1`
    color: #70C1B3;
    font-weight: bold;
    font-size: 5rem;
    font-family: 'Encode Sans Expanded', sans-serif;

    @media screen and (max-width:768px ){
        font-size: 4rem;
    }
    @media screen and (max-width:425px ){
        font-size: 3rem;
    }
   

`
export const LastName = styled.h1`
    color: #70C1B3;
    font-weight: bold;
    font-size: 4.5rem;
    font-family: 'Encode Sans Expanded', sans-serif;

    @media screen and (max-width:768px ){
        font-size: 3.5rem;
    }
    @media screen and (max-width:425px ){
        font-size: 2.5rem;
    }
   

`
export const Description = styled.p`
    color: white;
    font-size: 1.5rem;

    @media screen and (max-width:768px ){
        font-size: 1.10rem;
    }
   
`