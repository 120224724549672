import React from "react"
import {Button} from "../ButtonElement"
import { FaGithub, FaInstagram, FaTwitter} from 'react-icons/fa'
import {Fade} from 'react-awesome-reveal'
import { 
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle,
    BtnWrap, 
    ImgWrap, 
    Img,
    Icons,
    BT
    } from './InfoSectionElements'
    
    const InfoSection = ({
        lightBg,
        id, 
        imgStart, 
        topLine, 
        lightText,
        headLine, 
        darkText, 
        description, 
        buttonLabel, 
        img, 
        alt,
        primary,
        dark,
        dark2 }) => {
        const GithubPage = () =>{
            window.open("https://github.com/MandukoGR", '_blank') ;
        }
       
        const Portafolio = () =>{
            window.open("https://miscompetenciastec21.tec.mx/elumen/portfolio/LKkJqLuKEAf6DgE", '_blank') ;
        }
        return (
            <>
                <InfoContainer lightBg = {lightBg} id = {id}>
                    <InfoWrapper>
                        <InfoRow imgStart = {imgStart}>
                            <Column1>
                                <Fade duration={2000}>
                                    <TextWrapper>
                                        <TopLine lightBg = {lightBg}>{topLine}</TopLine>
                                        <Heading lightText = {lightText}>{headLine}</Heading>
                                        <Subtitle darkText = {darkText}>{description}</Subtitle>
                                        <BtnWrap>
                                            <Icons lightBg = {lightBg} onClick={GithubPage}><FaGithub/></Icons>
                                            
                                        </BtnWrap>
                                        {/* <BT>
                                            <Button 
                                                onClick={Portafolio}
                                                smooth = {true}
                                                duration = {500}
                                                spy = {true}
                                                exact = "true"
                                                offset = {-80}
                                                primary = {primary ? 1: 0}
                                                dark = {dark ? 0 : 1}
                                                dark2 = {dark2 ? 1 : 0}> {buttonLabel}
                                            </Button>
                                        </BT>
                                             */}
                                    </TextWrapper>
                                </Fade>
                                
                            </Column1>
                            <Column2>
                                <Fade direction = "right" duration = {2000}  >  
                                    <ImgWrap>
                                        <Img src = {img} alt = {alt}/>
                                    </ImgWrap>
                                </Fade>
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer>
                
            </>
        )
    }
    
    export default InfoSection
    
