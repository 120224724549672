import styled from "styled-components"


export const ExperienceContainer = styled.div`
    background-color: ${({lightBg}) => (lightBg ? '#70c1b3' : '#247ba0')};
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    width: auto;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;


`

export const ExperienceContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 768px){
        padding: 0 20px;
    }
`
export const ExperienceWrapper = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: ${({lessColumns}) => (lessColumns ? '1fr 1fr' : '1fr 1fr 1fr')};
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ExperienceCard = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const ExperienceIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`

export const ExperienceH1 = styled.h1`
    font-size: 2.5rem;
    color: black;
    margin-bottom: 10px;

    @media screen and (max-width: 768px){
        font-size: 2rem;
    }
`
export const ExperienceH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
    @media screen and (max-width: 768px){
        font-size: 0.5rem;
    }
`

export const ExperienceP = styled.p`
    font-size: 1rem;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 0.5rem;
    }
`

export const Title = styled.h1`
    font-size: 2.5rem;
    color: white;
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;

    @media screen and (max-width: 768px){
        font-size: 2rem;
    }
`




