import VideoAlien from '../../videos/VideoAlien.mp4';
import video1 from '../../videos/video1.mp4';
import VideoRarr from '../../videos/VideoRarr.mp4';
import VideoSmartCode from '../../videos/VideoSmartCode.mp4';
import VideoSimulation from '../../videos/VideoSimulation.mp4';
import videoGenesis from '../../videos/videoGenesis.mp4';

export const itemsListOne =["Partcipated as Program Manager and Architecture Owner", "Helped in all software development phases (analisis, design, development, testing, deployment)", "Helped in the definition of procesess, artefacts for development and in the consolidation of the department"]
export const itemsListTwo =["Worked as Product Owner", "Utilized SCRUM as part of an agile development approach.", "Used Kotlin for developing Android app."]
export const itemsListThree =["Designed the UI", "Developed the front-end using React.js", "Developed the back-end using Node.js", "Created a database using MariaDB"]
export const itemsListFour =["Used Python and Mesa for developing an agent based simulation.", "Used Unity for adding 3D graphics to the simulation."]
export const mainObj = {
    video: video1,
    title: 'Armando',
    title2: 'Gutiérrez',
    description: 'Software Developer',
    home: true,
}
export const projectObjOne = {
    video: videoGenesis,
    title: 'Genesis',
    title2:  '',
    description: 'A software developmet department with level 2.7 maturity in the CMMI',
    home: false,
}

export const projectObjTwo = {
    video: VideoRarr,
    title: 'Rarr',
    title2: 'Mobile app',
    description: 'A mobile event management application that allows users to create, edit, and publish events, as well as facilitate access to these events.',
    home: false,
}

export const projectObjThree = {
    video: VideoSmartCode,
    title: 'Human Resources',
    title2: 'Web app',
    description: 'A React and Node.js project for helping the Human Resources department.',
    home: false,
}

export const projectObjFour = {
    video: VideoSimulation,
    title: 'Traffic simulation',
    title2:  '',
    description: 'A simulation of cars moving in a three lane highway and reacting to a stopped car.',
    home: false,
}

