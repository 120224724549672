import React from "react";
import { DetailsContainer, Title, ListContainer, ListItem, DownloadButton, DescriptionGenesis } from "./DetailsSectionElements";
import { FaAndroid, FaYoutube } from "react-icons/fa";



const DetailsSection = ({itemsList, downloadButton, link, android, genesis}) => {
    return (
        <>
            <DetailsContainer>
               {genesis && 
               <>
                <Title>What is Genesis?</Title>
                <DescriptionGenesis>
                    Genesis is a software development department. We developed two web apps.
                    The first one is a web app for managing the spaces availability of "Centro Cultural Gómez Morín". The second one is a web app for a real state company "Kiara Bienes Raíces"
                
                </DescriptionGenesis>
               </>
              
               }
                
                <Title>How I contributed?</Title>
                <ListContainer>
                    {itemsList.map((item, index) => {
                        return (
                            <ListItem key={index}>
                                {item}
                            </ListItem>
                        )
                    })}
                </ListContainer>
               {downloadButton && android ? 
                <DownloadButton android = {android} href={link}target="_blank">
                <h1 style={{fontSize: "2rem"}}>Download</h1>
                <FaAndroid style={{color: "white", fontSize: "5rem", alignSelf: "center"}}/>
                </DownloadButton> :  <DownloadButton android = {android} href={link}target="_blank">
                <h1 style={{fontSize: "2rem"}}>Explicative video</h1>
                <FaYoutube style={{color: "white", fontSize: "5rem", alignSelf: "center"}}/>
                </DownloadButton>}
            
            
            </DetailsContainer>
        </>
      
    )
}


export default DetailsSection;



