import styled from "styled-components";


export const SkillsContainer = styled.div`
    background-color: ${({lightBg}) => (lightBg ? '#70c1b3' : '#247ba0')};
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    
    padding: 0 30px;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
`

export const SkillsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
    padding-left: 15%;

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`
export const Title = styled.h1`
    font-size: 2.5rem;
    color: white;
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;

    @media screen and (max-width: 768px){
        font-size: 2rem;
    }
`


export const SkillImage = styled.img`
    width: 60%;
    height: 50%;
    /* -o-object-fit:cover;
    object-fit: cover; */
    /* background: #232a34; */
    
`
