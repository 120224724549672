import React from 'react'
import { FaGithub, FaBars, FaBackward } from 'react-icons/fa'
import {Link} from 'react-router-dom'
import { Nav, NavbarContainer, NavbarGlogo, NavbarLinks, NavMenu, MobileIcon, NavbarItem} from './NavbarElements'
const Navbar = ({toggle, home}) => {
    const GithubPage = () =>{
        window.open("https://github.com/MandukoGR", '_blank') ;
    }
    
    return (
        <> 
            <Nav>
                {home ? <NavbarContainer>
                    <NavbarGlogo onClick={GithubPage}>
                        <FaGithub/>
                    </NavbarGlogo>
                    <MobileIcon onClick = {toggle}> <FaBars/> </MobileIcon>
                    <NavMenu>
                        <NavbarItem>
                            <NavbarLinks to="about"
                            smooth = {true} 
                            duration = {500} 
                            spy = {true} 
                            exact = 'true' 
                            offset = {-80}>About me</NavbarLinks>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLinks
                                to="experience"
                                smooth = {true} 
                                duration = {500} 
                                spy = {true} 
                                exact = 'true' 
                                offset = {-80}>Experience</NavbarLinks>
                        </NavbarItem>
                        <NavbarItem>
                             <NavbarLinks
                                  to="projects"
                                  smooth = {true} 
                                  duration = {500} 
                                  spy = {true} 
                                  exact = 'true' 
                                  offset = {-80}
                             
                             >Projects</NavbarLinks>
                        </NavbarItem>
                        <NavbarItem>
                             <NavbarLinks
                                to="skills"
                                smooth = {true} 
                                duration = {500} 
                                spy = {true} 
                                exact = 'true' 
                                offset = {-80}
                             >Skills</NavbarLinks>
                        </NavbarItem>
                    </NavMenu>
                    

                </NavbarContainer> :<NavbarContainer>
                   <Link 
                        to="/"
                        style={{ textDecoration: 'none', color: "white", fontSize: "2rem"}}
                        
                        
                        
                    >
                        
                        <FaBackward/>
                     
                    </Link>

                        

                    </NavbarContainer>}
                
            </Nav>
            
        </>
    )
}

export default Navbar
