import React from 'react'
import {HeroContainer, Description, HeroBg, VideoBg, Name,  LastName,TextSection, VideoBg2 } from './HeroElements'
import { Fade } from 'react-awesome-reveal'

const HeroSection = ({video, title, title2, description, home}) => {
    return (
       <>
        <HeroContainer>
        
            <HeroBg >
                {home ?
                    <VideoBg autoPlay loop muted src = {video} type = 'video/mp4'/>
                    :
                    <VideoBg2 autoPlay loop muted src = {video} type = 'video/mp4'/>
                }
            </HeroBg>
            <TextSection>
                <Fade direction = "left" duration = {2000}>
                    <Name>{title}</Name> 
                </Fade>
                <Fade direction = "left" duration={3000}>
                    <LastName>{title2}</LastName> 
                </Fade>
                <Fade duration={8500}>
                    <Description>{description}</Description>
                </Fade>
            </TextSection>
            
        </HeroContainer>
       
       
       </>
        
          
    )
}

export default HeroSection
