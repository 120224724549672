import styled from "styled-components"


export const DetailsContainer = styled.div`
    background-color: ${({lightBg}) => (lightBg ? '#70c1b3' : '#247ba0')};
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;

    @media screen and (max-width: 768px){
        padding-bottom: 0;
    }

`
export const DescriptionGenesis = styled.p`
    font-size: 1.5rem;
    color: white;
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;

    @media screen and (max-width: 768px){
        font-size: 1.2rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1rem;
    }

`
export const Title = styled.h1`
    font-size: 2.5rem;
    color: white;
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;

    @media screen and (max-width: 768px){
        font-size: 2rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
    }

`

export const ListContainer = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 0 50px;
    margin-left: 64px;

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 10px;
        padding: 0 35px;
    }

`

export const ListItem = styled.li`
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 768px){
        padding: 20px;
    }


`

export const DownloadButton = styled.a`
    background-color: ${({android}) => (android ? '#a4c639' : '#FF0000')};
    color: white;
    font-size: 1.5rem;
    align-items: center;
    display: flex;
    width: fit-content;
    justify-content: center;
    gap: 50px;
    padding: 10px 20px;
    border-radius: 30px;
    margin-top: 64px;
    margin-left: 64px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;


    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 768px){
        margin-left: 0;
        margin-right: 0;
        padding: 10px 20px;
        font-size: 1.2rem;
        margin-bottom: 64px;

    }

`