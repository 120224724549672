import React from "react";
import {Fade} from 'react-awesome-reveal'

import { ExperienceContainer, ExperienceCard, ExperienceIcon, ExperienceH1, ExperienceP, ExperienceWrapper, ExperienceH2, ExperienceContent, Title } from "./ExperienceSectionElements";

const aStyle = {
    textDecoration: "none",
    color: "black"
 }
export const ExperienceSection = (props) => {

    return (
       <>
          <ExperienceContainer id = {props.id} lightBg = {props.lightBg}>
            <Fade direction = "left" duration = {2000}>
                <ExperienceContent>
                    <Title>{props.title}</Title>
                    <ExperienceWrapper lessColumns = {props.lessColumns}>
                                <a style={aStyle} href={props.link1 + "1"} target={props.link1 !== "/projects" ? "_blank" : null}>
                                    <ExperienceCard>
                                        <ExperienceIcon src = {props.cardOneIcon}/>
                                        <ExperienceH1>{props.cardOneTopLine}</ExperienceH1>
                                        <ExperienceH2>{props.cardOneHeadLine}</ExperienceH2>
                                        <ExperienceP>{props.cardOneDescription}</ExperienceP>
                                    </ExperienceCard>
                                </a>
                                <a style={aStyle} href={props.link2 + "2"} target={props.link2 !== "/projects" ? "_blank" : null}>
                                    <ExperienceCard>
                                        <ExperienceIcon src = {props.cardTwoIcon}/>
                                        <ExperienceH1>{props.cardTwoTopLine}</ExperienceH1>
                                        <ExperienceH2>{props.cardTwoHeadLine}</ExperienceH2>
                                        <ExperienceP>{props.cardTwoDescription}</ExperienceP>
                                    </ExperienceCard>
                                </a>
                                <a style={aStyle} href={props.link3 + "3"} target={props.link3 !== "/projects" ? "_blank" : null}>
                                    <ExperienceCard>
                                        <ExperienceIcon src = {props.cardThreeIcon}/>
                                        <ExperienceH1>{props.cardThreeTopLine}</ExperienceH1>
                                        <ExperienceH2>{props.cardThreeHeadLine}</ExperienceH2>
                                        <ExperienceP>{props.cardThreeDescription}</ExperienceP>
                                    </ExperienceCard>
                                </a>
                                {props.lessColumns ?   <a style={aStyle} href={props.link4 + "4"} target={props.link4 !== "/projects" ? "_blank" : null}>
                                    <ExperienceCard>
                                        <ExperienceIcon src = {props.cardFourIcon}/>
                                        <ExperienceH1>{props.cardFourTopLine}</ExperienceH1>
                                        <ExperienceH2>{props.cardFourHeadLine}</ExperienceH2>
                                        <ExperienceP>{props.cardFourDescription}</ExperienceP>
                                    </ExperienceCard>
                                </a> : null}
                        </ExperienceWrapper>
                </ExperienceContent>
                  
            </Fade>
          </ExperienceContainer>         
       </>
    );
    };